import { DragOverlay, useDndContext } from '@dnd-kit/core';
import IconEyeClosed from 'components/Icons/IconEyeClosed';
import { useUserEmail } from 'contexts/auth';
import useFavorites from 'hooks/useFavorites';
import { useAtomValue } from 'jotai/utils';
import useHotkey from 'hooks/useHotkey';
import {
  useSetVisibleCalendars,
  visibleContactsCalendarsAtom,
} from 'hooks/useVisibleCalendars';
import React, { useCallback, useMemo, useRef } from 'react';
import ContactAvatar from './ContactAvatar';
import NavigationItem from './NavigationItem';
import useAvailableCalendars from './useAvailableCalendars';
import Avatar from 'joy/Avatar';
import { DroppableId } from 'types/drag-and-drop';
import EagerEventsLoader from './EagerEventsLoader';

export default React.memo(function ContactList() {
  const { addCalendar, removeCalendar, resetContactsCalendars } =
    useSetVisibleCalendars();
  const visibleContactsCalendarsLength = useAtomValue(
    visibleContactsCalendarsAtom
  ).length;

  const availableCalendars = useAvailableCalendars();
  const userEmail = useUserEmail();
  const favorites = useFavorites();
  const { active, over } = useDndContext();

  const isAvatarActive = useCallback(
    (email: string) => availableCalendars.some((cal) => cal.id === email),
    [availableCalendars]
  );

  const toggleContactVisibility = useCallback(
    (email: string) => () => {
      if (active) return;

      if (isAvatarActive(email)) {
        removeCalendar(email);
      } else {
        addCalendar(email);
      }
    },
    [removeCalendar, isAvatarActive, addCalendar, active]
  );

  const contactList = useMemo(() => {
    if (!userEmail || !favorites.length) return [];

    const filteredContacts = favorites.filter(
      (cal) =>
        !cal.emailAddress.includes('+') &&
        !cal.emailAddress.startsWith('team@') &&
        !cal.emailAddress.startsWith('contact@') &&
        !cal.emailAddress.startsWith('job@') &&
        !cal.emailAddress.startsWith('analytics') &&
        !cal.emailAddress.startsWith('support@')
    );

    return filteredContacts;
  }, [userEmail, favorites]);

  useHotkey('shift+escape', 'global', resetContactsCalendars);

  const draggingContact = contactList.find(
    (contact) => contact.id && contact.id === active?.id
  );
  const isOverSchedule = over?.id === DroppableId.SCHEDULE;

  const refs = useRef<Array<HTMLDivElement | null>>([]);

  if (contactList.length === 0) return null;

  return (
    <div className="mb-2.5 flex flex-col items-center space-y-2.5">
      <EagerEventsLoader
        emails={contactList.map((contact) => contact.emailAddress)}
        refs={refs}
      >
        {contactList.map((contact, index) => (
          <ContactAvatar
            key={`contact-list-${contact.id}`}
            reference={(e) => {
              refs.current[index] = e;
            }}
            index={index + 1}
            user={{
              id: contact.id,
              displayName: contact.displayName,
              avatar: contact.avatar,
              email: contact.emailAddress,
            }}
            color={
              availableCalendars.find(
                (calendar) => calendar.id === contact.emailAddress
              )?.color
            }
            active={isAvatarActive(contact.emailAddress)}
            onClick={toggleContactVisibility(contact.emailAddress)}
          />
        ))}
      </EagerEventsLoader>

      {!isOverSchedule && draggingContact && (
        <DragOverlay>
          <Avatar
            size={32}
            alternateStatus={true}
            src={draggingContact.avatar}
            name={draggingContact.displayName || draggingContact.emailAddress}
            isLoading={false}
            ring={undefined}
            isSidebar={true}
          />
        </DragOverlay>
      )}

      {visibleContactsCalendarsLength > 0 && (
        <NavigationItem
          shortcut="⇧esc"
          onClick={resetContactsCalendars}
          tooltipContent={`Hide ${visibleContactsCalendarsLength} ${
            visibleContactsCalendarsLength === 1 ? 'calendar' : 'calendars'
          }`}
        >
          <IconEyeClosed className={'h-4 w-4'} />
        </NavigationItem>
      )}
    </div>
  );
});
