import type { ColorFamily, EventCreator } from '@graphql-types@';
import classNames from 'classnames';
import UserPopover from 'components/UserPopover';
import { getAvatarStatus } from 'components/Status/utils';
import { useIsContactCalendarLoading } from 'hooks/contacts/useIsContactCalendarLoading';
import useHotkey from 'hooks/useHotkey';
import useUserStatus from 'hooks/useUserStatus';
import Avatar from 'joy/Avatar';
import React, { useCallback, useMemo, useState } from 'react';
import { DraggableType } from 'types/drag-and-drop';
import { useDraggable } from '@dnd-kit/core';
import ContextMenu, {
  ContextMenuType,
  useContextMenu,
  useUpdateContextMenu,
} from 'joy/ContextMenu';
import { DropdownItem } from 'joy/Dropdown';
import Button from 'joy/Button';
import { ARIA_LABEL_RIGHT_CLICK_MENU_OPTIONS } from 'utils/constants';
import { useUnfollowUser, useViewUserProfile } from './useUserActions';
import { mergeRefs } from 'react-laag';

interface Props {
  active: boolean;
  index: number;
  color?: ColorFamily;
  user: Pick<EventCreator, 'id' | 'email' | 'displayName' | 'avatar'>;
  onClick: () => void;
  reference?: React.Ref<HTMLDivElement>;
}

export default function ContactAvatar({
  active,
  index,
  color,
  user,
  onClick,
  reference,
}: Props): JSX.Element {
  const {
    setNodeRef,
    listeners,
    attributes,
    active: dragging,
  } = useDraggable({
    id: user.id || '',
    disabled: !user.id,
    data: {
      type: DraggableType.CONTACT,
      contact: user,
    },
  });
  const { status } = useUserStatus({ userEmail: user.email });
  const isLoading = useIsContactCalendarLoading(user.email || '');

  useHotkey(`shift+${index}`, 'global', onClick);

  const unfollowContact = useUnfollowUser({
    userId: user.id || undefined,
    userEmail: user.email || undefined,
  });

  const viewUserProfile = useViewUserProfile({
    userEmail: user.email || undefined,
  });

  const menuItems: DropdownItem[] = useMemo(() => {
    const items: DropdownItem[] = [
      {
        type: 'option',
        value: 'View profile',
        onSelect: viewUserProfile,
      },
      {
        type: 'option',
        value: 'Show calendar',
        onSelect: onClick,
      },
      {
        type: 'option',
        value: 'Unfollow',
        onSelect: unfollowContact,
      },
    ];
    return items;
  }, [onClick, viewUserProfile, unfollowContact]);

  const contextMenu = useContextMenu();
  const isContextMenuOpen = contextMenu === ContextMenuType.Contact;
  const { openContextMenu, closeContextMenu } = useUpdateContextMenu();

  const [isItemMenuActive, setIsItemMenuActive] = useState(false);

  const onContextMenuClose = useCallback(() => {
    closeContextMenu(ContextMenuType.Contact);
    setIsItemMenuActive(false);
  }, [closeContextMenu]);

  const onContextMenuOpen = useCallback(() => {
    openContextMenu(ContextMenuType.Contact);
    setIsItemMenuActive(true);
  }, [openContextMenu]);

  return (
    <ContextMenu
      items={menuItems}
      onClose={onContextMenuClose}
      placement={'right-start'}
    >
      <div
        ref={mergeRefs(setNodeRef, reference)}
        {...listeners}
        {...attributes}
      >
        <UserPopover
          user={user}
          placement="right-start"
          // do not show popover if context menu is open
          // or the avatar is being dragged
          disabled={!!dragging?.id || isContextMenuOpen}
        >
          <Button
            ariaLabel={ARIA_LABEL_RIGHT_CLICK_MENU_OPTIONS}
            className="group flex h-full w-full items-center justify-center rounded-full"
            onClick={onClick}
            onContextMenu={onContextMenuOpen}
          >
            <Avatar
              isSidebar={true}
              size={34}
              alternateStatus={true}
              src={user.avatar}
              name={user?.displayName || user?.email || ''}
              isLoading={isLoading}
              ring={active ? color : undefined}
              status={getAvatarStatus(status)}
              statusClassName="border-3 border-background"
              className={classNames(
                'transition-opacity group-hover:opacity-100',
                {
                  'opacity-100': isItemMenuActive,
                  'opacity-75': !isItemMenuActive,
                }
              )}
            />
          </Button>
        </UserPopover>
      </div>
    </ContextMenu>
  );
}
